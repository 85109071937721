import type { Prices } from "~/types/product-catalogue";

const VARIANT_IDENTIFIERS = [
  "default",
  "discount",
  "price-inc-vat",
  "price-ex-vat",
] as const;

type VariantIdentifier = (typeof VARIANT_IDENTIFIERS)[number];

const KEY_MAPPING: Record<VariantIdentifier, keyof Prices> = {
  default: "default",
  discount: "discount",
  "price-inc-vat": "incVat",
  "price-ex-vat": "exVat",
};

type PriceVariant = {
  identifier: string;
  price?: number | null;
};

/**
 * Convert a list of price variants to a more usable format.
 *
 * @param variants
 * @param basePrice - The base price to use if a variant is missing
 */
export function getPrices(
  variants: PriceVariant[],
  basePrice: number | null | undefined,
): Prices {
  return variants.reduce<Prices>(
    (prices, variant) => {
      const id = VARIANT_IDENTIFIERS.find((it) => it === variant.identifier);

      if (id && typeof variant.price === "number") {
        const key = KEY_MAPPING[id];
        prices[key] = variant.price;

        if (id === "price-inc-vat" && !prices.default) {
          // If the default price is not set by basePrice or by the default price variant, we assume that price-inc-vat is the default price.
          prices.default = variant.price;
        } else if (
          id === "default" &&
          basePrice &&
          variant.price !== basePrice
        ) {
          console.warn("default price variant was different to base price", {
            basePrice,
            variantPrice: variant.price,
          });
        }
      }

      return prices;
    },
    {
      default: basePrice ?? 0,
    },
  );
}

import validate from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/middleware/auth.global.ts";
import manifest_45route_45rule from "/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/node_modules/.pnpm/nuxt@3.12.4_eslint@8.57.0_sass@1.77.8_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-auth": () => import("/builds/aschehoug/aschehoug-utdanning/shop-app/frontend/middleware/check-auth.ts")
}
import type { PublicRuntimeConfig } from "nuxt/schema";
import { FetchError } from "ofetch";

export function useCoreApi(
  basePath: string,
  getHost: (urls: PublicRuntimeConfig["urls"]["core"]) => string = (core) =>
    core.eCommerce,
) {
  const config = useRuntimeConfig();
  const host = getHost(config.public.urls.core);
  const rootUrl = [host, basePath].filter(Boolean).join("/");
  const auth = useAuthorizeStore();
  const { time } = useServerTiming();

  return {
    rootUrl,

    endpoint(...parts: (string | undefined)[]) {
      if (parts.some((part) => part === undefined)) {
        throw new Error("Endpoint had invalid parts: " + parts.join(", "));
      }

      return [rootUrl, ...parts].join("/");
    },

    async $fetch<T>(
      url: string,
      init?: {
        method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
        body?: string;
        headers?: Record<string, string>;
        handle404asNull?: boolean;
      },
    ) {
      let headers = await auth.headers();
      if (!headers?.Authorization) {
        return;
      }

      headers = {
        ...headers,
        ...init?.headers,
      };

      return await time("coreApi.fetch", async () => {
        try {
          return await $fetch<T>(url, {
            headers,
            method: init?.method ?? "GET",
            body: init?.body,
          });
        } catch (e) {
          if (
            init?.handle404asNull &&
            e instanceof FetchError &&
            e.response?.status === 404
          ) {
            return null;
          }

          handleError(e, "Failed to fetch from core API");
          throw e;
        }
      });
    },
  };
}

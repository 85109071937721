import type { MeteredPlan } from "~/types/product-catalogue";

export interface Prices {
  hasDiscount: boolean;
  appliedWithTax: number;
  appliedWithoutTax: number;
  originalWithTax: number;
  originalNoTax: number;
  meteredPrice: boolean;
}

export interface Priceable {
  price?: number;
  tax?: number;
  priceDiscount?: number;
  subscriptionsPlan?: MeteredPlan;
}

export function calculatePrices(
  item: Priceable,
  totStudents: number | undefined,
  period: "initial" | "recurring" = "recurring",
): Prices {
  const prices = item.subscriptionsPlan?.period?.prices?.[period];
  const tax = item.tax ? item.tax / 100 : 0;

  let noDiscountWithTax = prices?.incVat ?? prices?.default ?? item.price ?? 0;
  let meteredPrice = false;

  if (totStudents !== undefined) {
    const tier = item.subscriptionsPlan?.meteredVariables
      ?.sort((a, b) => b.threshold - a.threshold)
      .find((tier) => tier.threshold < totStudents);

    if (tier && tier.price !== noDiscountWithTax) {
      meteredPrice = true;
      noDiscountWithTax = tier.price;
    }
  }

  const noDiscountNoTax = noDiscountWithTax / (1 + tax);

  const discount = prices?.discount ?? item.priceDiscount;
  const withDiscountNoTax = discount
    ? noDiscountNoTax - discount
    : noDiscountNoTax;

  const withDiscountWithTax = withDiscountNoTax * (1 + tax);

  return {
    hasDiscount: !!discount,
    appliedWithTax: withDiscountWithTax,
    appliedWithoutTax: withDiscountNoTax,
    originalWithTax: noDiscountWithTax,
    originalNoTax: noDiscountNoTax,
    meteredPrice,
  };
}

import type { ProductSource } from "~/types/core/common";
import type {
  CartItemDto,
  CartDto,
  SubscriptionStartRequest,
} from "~/types/core/ecommerce/cart";
import type { MaybeRef } from "vue";

export function useCartApi(id: MaybeRef<string | undefined>) {
  const api = useCoreApi("carts");

  return {
    async getActiveOrNewCart(productSource?: ProductSource) {
      const url = new URL(api.rootUrl);
      if (productSource) {
        url.searchParams.set("productSource", productSource);
      }

      return await api.$fetch<CartDto>(url.href);
    },

    async deleteCart() {
      return await api.$fetch<void>(api.endpoint(unref(id)), {
        method: "DELETE",
      });
    },

    async addItemToCart(item: Omit<CartItemDto, "uuid">) {
      return await api.$fetch<CartDto>(api.endpoint(unref(id), "item"), {
        method: "POST",
        body: JSON.stringify(item),
      });
    },

    async deleteItemFromCart(itemId: string) {
      return await api.$fetch<CartDto>(
        api.endpoint(unref(id), "item", itemId),
        {
          method: "DELETE",
        },
      );
    },

    async updateCartItem({ uuid: itemId, ...item }: CartItemDto) {
      return await api.$fetch<CartItemDto>(
        api.endpoint(unref(id), "item", itemId),
        {
          method: "PUT",
          body: JSON.stringify(item),
        },
      );
    },

    async startSubscriptions(request: SubscriptionStartRequest) {
      return await api.$fetch<void>(
        api.endpoint(unref(id), "start-subscriptions"),
        {
          method: "POST",
          body: JSON.stringify(request),
        },
      );
    },
  };
}

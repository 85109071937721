<script setup lang="ts">
const props = defineProps({
  fill: {
    type: String as PropType<string>,
    required: false,
    default: "white",
  },
});

const reverseColor = props.fill === "white" ? "black" : "white";
</script>

<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    alt="Facebook ikon"
  >
    <path
      d="M25 24.9827C30.5228 19.4629 30.5228 10.5136 25 4.99391C19.4772 -0.525834 10.5228 -0.525834 5 4.99391C-0.522848 10.5136 -0.522848 19.4629 5 24.9827C10.5228 30.5024 19.4772 30.5024 25 24.9827Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3637 25.279H16.203V16.5926H19.0389L19.606 13.0837H16.203V10.4305C16.203 9.44646 17.1631 8.89029 18.0788 8.89029H19.7368V5.98052L16.77 5.85222C13.9341 5.68094 12.3637 7.86328 12.3637 10.4735V13.0837H9.13525V16.5926H12.3637V25.279Z"
      :fill="reverseColor"
    />
  </svg>
</template>

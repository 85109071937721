<script setup lang="ts">
const props = defineProps({
  fill: {
    type: String as PropType<string>,
    required: false,
    default: "white",
  },
});

const reverseColor = props.fill === "white" ? "black" : "white";
</script>

<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    alt="Instagram ikon"
  >
    <path
      d="M25 24.9831C30.5228 19.4634 30.5228 10.5141 25 4.9944C19.4772 -0.525345 10.5228 -0.525345 5 4.9944C-0.522849 10.5141 -0.522848 19.4634 5 24.9831C10.5228 30.5029 19.4772 30.5029 25 24.9831Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7763 5.95117H19.1866C21.8883 5.95117 24.1105 8.10694 24.1105 10.7278V18.8438C24.1105 21.4647 21.8883 23.6204 19.1866 23.6204H10.7763C8.07457 23.6204 5.85229 21.4647 5.85229 18.8438V10.7278C5.85229 8.10694 8.07457 5.95117 10.7763 5.95117ZM19.8834 9.03709C20.45 9.03709 20.9294 9.50217 20.9294 10.0518C20.9294 10.6013 20.45 11.0664 19.8834 11.0664C19.2737 11.0664 18.8375 10.6013 18.8375 10.0518C18.8375 9.50217 19.273 9.03709 19.8834 9.03709ZM14.9595 9.96725H15.0033C17.705 9.96725 19.9712 12.1656 19.9712 14.7864C19.9712 17.4492 17.705 19.6056 15.0033 19.6056H14.9595C12.2578 19.6056 10.0355 17.4498 10.0355 14.7864C10.0355 12.1656 12.2578 9.96725 14.9595 9.96725ZM14.9595 11.616H15.0033C16.7901 11.616 18.2716 13.0532 18.2716 14.7864C18.2716 16.5616 16.7901 17.9988 15.0033 17.9988H14.9595C13.1728 17.9988 11.6912 16.5616 11.6912 14.7864C11.6912 13.0532 13.1728 11.616 14.9595 11.616ZM10.8201 7.47347H19.1427C21.0165 7.47347 22.5413 8.95321 22.5413 10.7703V18.8019C22.5413 20.6196 21.0159 22.0987 19.1427 22.0987H10.8201C8.94631 22.0987 7.42155 20.619 7.42155 18.8019V10.7703C7.42155 8.95259 8.94694 7.47347 10.8201 7.47347Z"
      :fill="reverseColor"
    />
  </svg>
</template>

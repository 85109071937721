import {
  type GradeCode,
  type Level,
  type ProductFormat,
  type SubjectCategory,
} from "~/utils/constants";
import type { Packet, PacketVariant } from "~/types/universe";
import type { ProductCard } from "~/types/product-catalogue";
import type {
  SingleProduct,
  SingleProductVariant,
} from "~/types/single-product";
import type { Subject } from "~/types/core/common";

// TODO: Does not look nice if the product's grades spans multiple levels (i.e. both grunnskole and vgs)
export function getGradeRange(product: { grades?: GradeCode[] }) {
  const grades = product.grades || [];

  if (grades.length === 0) {
    return "";
  }

  if (grades.length === 1) {
    const grade = grades[0]!;

    if (grade.startsWith("aarstrinn")) {
      return grade.replace("aarstrinn", "");
    } else if (grade === "vok") {
      return "Voksenopplæring";
    } else if (grade === "prep" || grade === "forkurs") {
      return "Forkurs";
    } else {
      return grade.toUpperCase();
    }
  }

  const ranges: string[] = [];
  let start: string | undefined;
  let current: string | undefined;
  let inRange = false;
  for (const grade of GRADES) {
    if (grades.includes(grade)) {
      const formatted = grade.replace("aarstrinn", "").toUpperCase();
      current = formatted;
      if (!inRange) {
        start = formatted;
        inRange = true;
      }
    } else if (inRange && start) {
      inRange = false;
      if (current && current !== start) {
        ranges.push(`${start}-${current}`);
      } else {
        ranges.push(start);
        start = undefined;
      }
    }
  }

  return ranges.join(", ");
}

const listFormat = new Intl.ListFormat("nb", {
  style: "long",
  type: "conjunction",
});

export function getSchool(product: { levels?: Level[] }) {
  const norwegianLevels = product.levels
    ?.map((it) => {
      const option = LEVEL_OPTIONS.find((option) => option.value === it);
      return option?.title;
    })
    ?.filter(truthyAndDistinct);

  return listFormat.format(norwegianLevels || []);
}

export function getFormats(product: { formats?: ProductFormat[] }) {
  return listFormat.format(
    product.formats
      ?.map((f) => PRODUCT_FORMAT_OPTIONS.find((it) => it.value === f)?.tagName)
      ?.filter(truthyAndDistinct) ?? [],
  );
}

export function toProductCard<T extends Packet | SingleProduct>(
  it: T,
): ProductCard<T> {
  if (isSingleProduct(it)) {
    // FIXME: Both of these could probably be done directly in the mapper
    it.coverCard ??= {
      img: "https://media.crystallize.com/au-skole-dev/23/12/6/8/aunivers_01.png",
      alt: "Forside av bok",
    };

    it.cardColor ??= "productcard-purple";
  }

  let subjectCategories: SubjectCategory[];

  const subjects = it.variants
    .flatMap((v) => v.subjects ?? [])
    .filter(truthyAndDistinct);

  if (subjects.length === 0) {
    subjectCategories = ["other"];
  } else {
    subjectCategories = subjects
      .map((subject) => SUBJECT_MAP[subject] ?? "other")
      .filter(truthyAndDistinct);

    if (subjects.length > 1 && !subjectCategories.includes("multiple")) {
      subjectCategories.push("multiple");
    }
  }

  return {
    id: it.id,
    name: it.name,
    title: it.title,
    type: it.type,
    slug: it.slug,
    discount: it.discount,
    formats: it.formats,
    biblioFormats: ("biblioFormats" in it && it.biblioFormats) || [],
    background: it.cardColor,
    cover: it.coverCard,
    grades: it.grades,
    levels: it.levels,
    subjects,
    subjectCategories,
  };
}

type SimpleProduct = { type: "packet" | "single-product"; levels?: Level[] };

/**
 * Sorts products by type (packets before single products) and level  (lowest level first).
 */
export function byTypeAndLevel<
  A extends SimpleProduct,
  B extends SimpleProduct,
>(a: A, b: B) {
  if (a.type !== b.type) {
    return isPacket(a) ? -1 : 1;
  }

  const aLevels = a.levels?.map((it) => LEVELS.indexOf(it));
  const bLevels = b.levels?.map((it) => LEVELS.indexOf(it));

  if (!aLevels && !bLevels) {
    return 0;
  }

  if (!aLevels) {
    return 1;
  }

  if (!bLevels) {
    return -1;
  }

  return Math.min(...aLevels) - Math.min(...bLevels);
}

export function isPacket(product: unknown): product is Packet {
  return isObjectWithKey(product, "type") && product.type === "packet";
}

export function isPacketVariant(variant: unknown): variant is PacketVariant {
  return (
    isObjectWithKey(variant, "parentType") && variant.parentType === "packet"
  );
}

export function isSingleProduct(product: unknown): product is SingleProduct {
  return isObjectWithKey(product, "type") && product.type === "single-product";
}

export function isSingleProductVariant(
  variant: unknown,
): variant is SingleProductVariant {
  return (
    isObjectWithKey(variant, "parentType") &&
    variant.parentType === "single-product"
  );
}

const SUBJECT_MAP: Record<Subject, SubjectCategory> = {
  NOR: "norwegian",
  ENG: "languages",
  FSP: "languages",
  SPA: "languages",
  TYS: "languages",
  FRA: "languages",
  FYS: "science",
  NAT: "science",
  MAT: "science",
  GFG: "science",
  KJE: "science",
  INF: "science",
  TVE: "multiple",
  KRO: "other",
  MED: "other",
  KHV: "other",
  MUS: "other",
  MHE: "other",
  PSY: "other",
  HEL: "other",
  PED: "other",
  SAF: "social-studies",
  GEO: "social-studies",
  HIS: "social-studies",
  SAK: "social-studies",
  SOK: "social-studies",
  SAG: "social-studies",
  SOS: "social-studies",
  POM: "social-studies",
  POL: "social-studies",
  REL: "social-studies",
  KRL: "social-studies",
};

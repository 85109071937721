import { userSessionState } from "@aschehoug/aschehoug-oidc-client";

/**
 * Global middleware that ensures that, if the user is authenticated, a cookie is populated with a valid access token.
 *
 * Will also fetch user data from the backend if the user is authenticated.
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const token = useCookie("__accessToken");

  if (token.value && isExpired(token.value)) {
    token.value = null;
  }

  if (!token.value && import.meta.client) {
    try {
      const client = useOidcClient();
      const res = await client.startAuthSession();
      if (res === userSessionState.hasActiveSession) {
        let newToken = client.getAccessToken();
        if (isExpired(newToken) && !!client.getAmTokenData()?.refresh_token) {
          try {
            const state = await client.refreshAccessToken();
            if (state === userSessionState.hasActiveSession) {
              newToken = client.getAccessToken();
            }
          } catch (e) {
            handleError(e, "Error refreshing access token", false);
          }
          token.value = isExpired(newToken) ? null : newToken;
        } else if (newToken) {
          token.value = newToken;
        }
      } else {
        token.value = null;
      }
    } catch (e) {
      handleError(e, "Error starting auth session", false);
    }
  }

  const { time } = useServerTiming();

  const settingsPromise = callOnce(() =>
    time("fetch_settings", () => useSettingsStore().fetchSettings(to)),
  );

  if (token.value) {
    const auth = useAuthorizeStore();
    const cart = useCartStore();

    auth.accessToken = token.value;
    const userPromise = callOnce(() =>
      time("fetch_user", () => auth.fetchUserData()),
    );
    const cartPromise = callOnce(() =>
      time("fetch_cart", () => cart.refreshRemoteCart(to)),
    );
    const previewsPromise = callOnce(() =>
      time("fetch_previews", () => useProductPreviewStore().fetchPreviews()),
    );

    const results = await Promise.allSettled([
      userPromise,
      cartPromise,
      previewsPromise,
    ]);

    if (import.meta.client) {
      await cart.hydrateTempItems(to);
    }

    for (const result of results) {
      if (result.status === "rejected") {
        console.error(result.reason);
      }
    }
  }

  try {
    await settingsPromise;
  } catch (e) {
    handleError(e, "Error fetching settings", false);
  }
});

import type { QuoteType } from "@/types/quotes";

export function useCheckIfContentIsVideo(content: string): boolean {
  if (
    content.includes("https://www.youtu.be") ||
    content.includes("https://www.youtube.com") ||
    content.includes("https://player.vimeo") ||
    content.includes("https://www.vimeo") ||
    content.includes("https://vimeo")
  ) {
    return true;
  } else {
    return false;
  }
}

export function useCheckIfContentIsImage(content: string): boolean {
  if (content.includes("<img")) {
    return true;
  } else {
    return false;
  }
}

export function useSimpleQuoteToQuoteType(quote: string): QuoteType {
  const trimmedQuote = quote
    .replace("<quote>", "")
    .replace("</quote>", "")
    .trim();
  if (trimmedQuote.includes("@")) {
    const splittedQuote = trimmedQuote.split("@");
    const body = splittedQuote[0];

    if (splittedQuote[1] && splittedQuote[1].includes(",")) {
      const authorAndCompany = splittedQuote[1].split(",");
      const author = authorAndCompany[0];
      const company = authorAndCompany[1];

      return { body, author, company };
    }
    const author = splittedQuote[1];
    return { body, author, company: "" };
  }
  return { body: trimmedQuote, author: "", company: "" };
}

export function useParseImageFromHtml(html: string) {
  const splittedHtml = html.split('"');
  const src = splittedHtml[1];
  const alt = splittedHtml[3];
  const caption = splittedHtml[4]?.split(">")[2]?.split("<")[0];
  return { src, alt, caption };
}

export async function useArticles() {
  const store = useArticlesStore();
  return useAsyncData("articles", () => store.fetchArticles(), {
    lazy: true,
    server: false,
  });
}

import type { LicenseDetails } from "~/types/core/external/license";
import type { SubscriptionContractDto } from "~/types/core/ecommerce/subscriptions";
import type { ToastFn } from "~/utils/injection";

interface SubscriptionPrice {
  perLicence: number;
  total: number;
}

export interface SubscriptionPurchaseType {
  uuid: string;
  isbn: string;
  product?: string;
  access: number;
  renewal: string;
  isOpen: boolean;
  initial: SubscriptionPrice;
  recurring: SubscriptionPrice;
  ownerId: string;
}

export interface PurchaseType {
  isbn: string;
  product?: string;
  price: number;
  access: number;
  startdate: string;
  duration: string;
  ordernumber?: string;
  pdf: string;
  isOpen: boolean;
}

export const usePurchasesStore = defineStore("purchases", {
  state(): {
    subscriptions: Array<SubscriptionPurchaseType>;
    purchases: Array<PurchaseType>;
    trials: Array<LicenseDetails>;
  } {
    return {
      subscriptions: [],
      purchases: [],
      trials: [],
    };
  },

  actions: {
    clear() {
      this.subscriptions = [];
      this.purchases = [];
      this.trials = [];
    },
    async updateAmount(
      id: string,
      newAmount: number | null,
      toast?: { success: ToastFn; error: ToastFn } | null,
    ) {
      const subscription = this.subscriptions.find((s) => s.uuid === id);
      if (
        subscription &&
        typeof newAmount === "number" &&
        !isNaN(newAmount) &&
        newAmount !== subscription.access
      ) {
        const old = subscription.access;
        subscription.access = newAmount;

        try {
          const updatedSubscription =
            await useSubscriptionsApi().editSubscription(subscription.uuid, {
              quantity: newAmount,
              ean: subscription.isbn,
            });

          if (updatedSubscription) {
            this.subscriptions = this.subscriptions.map((it) =>
              it.uuid === updatedSubscription.uuid
                ? toSubscription(updatedSubscription)
                : it,
            );
            toast?.success("Antall lisenser ble oppdatert til " + newAmount);
          } else {
            subscription.access = old;
          }
        } catch (e) {
          handleError(e, "Failed to update subscription", false);
          subscription.access = old;
          toast?.error(
            "Vi klarte ikke å oppdatere antall lisenser. Prøv igjen senere, eller kontakt kundeservice om problemet vedvarer.",
          );
        }
      }
    },
    async fetchPurchases() {
      try {
        const api = usePurchasesApi();
        const data = await api.getOldPurchaseHistory();

        if (data) {
          this.purchases = data
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            )
            .flatMap((purchase) =>
              purchase.items.map<PurchaseType>((item) => ({
                isbn: item.productId,
                product: item.productName,
                access: item.amount,
                ordernumber: purchase.orderId,
                startdate: formatNorwegianDate(new Date(purchase.createdAt)),
                isOpen: purchase.status !== "FINISHED", // FIXME: Is this correct?
                price: (purchase.orderAmount ?? 0) / 100, // FIXME: Calculate per-item price when algorithm has been updated. Currently shows price for entire order.
                duration: "", // FIXME: Get from subscriptions endpoint when it is ready (https://acoundervisning.atlassian.net/browse/CORE-2077)
                pdf: `${useRuntimeConfig().public.urls.core.eCommerce}/receipt/?orderid=${purchase.orderId}&productsource=CRYSTALLIZE_SKOLE`,
              })),
            );
        }
      } catch (error) {
        handleError(error, "Error fetching purchases");
      }
    },

    async fetchSubscriptions() {
      try {
        const api = useSubscriptionsApi();
        const data = (await api.getSubscriptions()) ?? [];

        this.subscriptions = data
          .sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          )
          .map(toSubscription);
      } catch (error) {
        handleError(error, "Error fetching subscriptions");
      }
    },

    async fetchLicenses() {
      if (this.trials.length > 0) {
        return this.trials;
      }

      const api = useLicensesApi();

      try {
        const data = (await api.getLicenseDetails()) ?? [];

        const now = new Date();
        this.trials = data.filter(
          (it) =>
            it.licenseModel === "TRIAL_SCHOOL" &&
            it.startDate &&
            (!it.endDate ||
              (new Date(it.endDate) > now && new Date(it.startDate) < now)),
        );

        return this.trials;
      } catch (e) {
        handleError(e, "Error fetching user licenses");
        return this.trials;
      }
    },

    async hasActiveTrial(isbn: string) {
      try {
        const activeTrials = await this.fetchLicenses();

        return activeTrials.some((trial) => trial.ean === isbn);
      } catch {
        // This is already logged, just ignore it.
        return false;
      }
    },
  },
});

function toSubscription(
  subscription: SubscriptionContractDto,
): SubscriptionPurchaseType {
  return {
    ownerId: subscription.ownerId,
    uuid: subscription.uuid,
    isbn: subscription.cartItem.productId,
    product: subscription.cartItem.productName,
    initial: subscriptionPrice(subscription, "initial"),
    recurring: subscriptionPrice(subscription, "recurring"),
    access: subscription.cartItem.amount,
    renewal: formatNorwegianDate(new Date(subscription.renewAt)),
    isOpen: false, // TODO
  };
}

function subscriptionPrice(
  subscription: SubscriptionContractDto,
  key: "initial" | "recurring",
): SubscriptionPrice {
  const total = subscription[`${key}Price`] / 100;
  return {
    perLicence: total / subscription.cartItem.amount,
    total,
  };
}

import type { CartDto, CartItemDto } from "~/types/core/ecommerce/cart";
import type { ProductVariant } from "~/types/product-catalogue";
import type { RouteLocationNormalized } from "vue-router";
import type { Prices } from "~/utils/prices";
import { z } from "zod";

export interface SimpleCartItem {
  id: string;
  isbn: string;
  amount: number;
  fixedAmount: boolean;
  isTrial: boolean;
  name: string | undefined;
  variant: ProductVariant<string> | undefined;
  prices?: Prices;
  type: string;
}

export interface NewCartItem {
  variant: ProductVariant<string>;
  amount: number;
}

export interface AddOptions {
  route: RouteLocationNormalized;
  onSuccess?: () => void;
  onTemp?: () => void;
}

export interface CartSummary {
  total: number;
  discount: number;
  tax: number;
  basketId?: string;
  userId?: string;
  status?: string;
}

// Change this key if tempCartItemSchema is changed in a non-backwards compatible way
const TEMP_CART_KEY = "temp-cart-v1";
const tempCartItemSchema = z
  .string()
  .nullable()
  .transform((it) => (typeof it === "string" ? JSON.parse(it) : it))
  .pipe(
    z.array(
      z.object({
        isbn: z.string().regex(/^\d{13}$/),
        amount: z.number().positive(),
      }),
    ),
  );

export type TempCartItem = z.infer<typeof tempCartItemSchema>[number];

export const useCartStore = defineStore("cart", () => {
  const tempCart = useSessionStorage(TEMP_CART_KEY, [], {
    serializer: {
      read: tempCartItemSchema.parse,
      write: JSON.stringify,
    },
  });

  const trial = useSessionStorage<string | null>("trial", null);

  const remoteCart = ref<CartDto>();

  const cartId = computed(() => remoteCart?.value?.uuid);

  const cartApi = useCartApi(cartId);

  const checkoutApi = useCheckoutApi();

  const auth = useAuthorizeStore();

  const productCatalogue = useProductCatalogueStore();

  const items = computed<SimpleCartItem[]>(() => {
    if (trial.value) {
      const variant = productCatalogue.getVariant(trial.value);
      // If we have a trial set, we only show that.
      if (variant) {
        return [
          {
            id: variant.isbn,
            isbn: variant.isbn,
            amount: 1,
            fixedAmount: true,
            isTrial: true,
            name: variant.title,
            variant,
            type: "Prøvelisens",
          },
        ];
      }
    }

    if (!remoteCart.value) {
      return [];
    }

    return remoteCart.value.cartItems.map((item) => {
      const variant = productCatalogue.getVariant(item.productId);
      // TODO: Verify that variant subscription plan is correct and matches the cart item
      return {
        id: item.uuid,
        isbn: item.productId ?? variant?.isbn,
        amount: item.amount,
        isTrial: false,
        name: item.productName ?? variant?.title,
        variant,
        prices: variant && calculatePrices(variant, auth.totStudents),
        fixedAmount: variant?.fixedAmount ?? false,
        type: isSingleProductVariant(variant)
          ? "Digital bok"
          : "Digitalt læremiddel",
      };
    });
  });

  const summary = computed(() =>
    items.value.reduce<CartSummary>(
      (sum, item) => {
        const prices = item.prices;
        if (prices) {
          sum.tax +=
            item.amount * (prices.appliedWithTax - prices.appliedWithoutTax);

          const amount = item.amount * prices.appliedWithTax;
          sum.total += amount;

          if (prices.hasDiscount) {
            sum.discount += item.amount * prices.originalWithTax - amount;
          }
        }

        return sum;
      },
      {
        total: 0,
        discount: 0,
        tax: 0,
      },
    ),
  );

  const count = computed(() => items.value.length);

  const isEmpty = computed(() => count.value === 0);

  const toast = inject(keys.toast, null);

  /**
   * Update the amount of a cart item. Will be set eagerly before the API call, and reverted if the call fails.
   *
   * @param itemOrId - The uuid of the cart item to update, or the actual item
   * @param newAmount - The new amount to set
   */
  async function updateAmount(
    itemOrId: string | CartItemDto,
    newAmount: number,
  ) {
    if (!auth.isTeacher) {
      return;
    }

    let item: CartItemDto | undefined;
    if (typeof itemOrId === "string") {
      if (!remoteCart.value) {
        return;
      }

      item = remoteCart.value.cartItems.find((i) => i.uuid === itemOrId);
    } else {
      item = itemOrId;
    }

    if (item) {
      const oldAmount = item.amount;

      if (oldAmount === newAmount) {
        return;
      }

      item.amount = newAmount;

      try {
        await cartApi.updateCartItem({
          ...item,
          amount: newAmount,
        });
      } catch {
        item.amount = oldAmount;
        toast?.error(
          "Kunne ikke oppdatere antall på varen, ta kontakt med kundeservice om feilen vedvarer.",
        );
      }
    }
  }

  const totStudents = computed(() => auth.totStudents);

  // Update the amount of fixed amount items when the total number of students changes
  watch(totStudents, (newValue, oldValue) => {
    if (!remoteCart.value || oldValue === newValue) {
      return;
    }

    for (const item of remoteCart.value.cartItems) {
      const variant = productCatalogue.getVariant(item.productId);
      if (variant && variant.fixedAmount) {
        updateAmount(item, newValue);
      }
    }
  });

  return {
    count,
    remoteCart,
    isEmpty,
    summary,
    items,

    /**
     * Fetch the remote cart. If the cart is not already fetched, it will be fetched from the API.
     *
     * @param route - The route to use for fetching the product catalogue
     */
    async fetchRemoteCart(route: RouteLocationNormalized) {
      if (!auth.isTeacher) {
        return;
      }

      await productCatalogue.ensureProductCatalogue(route);

      const newCart = await cartApi.getActiveOrNewCart();
      if (newCart) {
        remoteCart.value = newCart;
      }
    },

    /**
     * Refresh the remote cart. If the cart is not already fetched, it will be fetched from the API.
     *
     * @param route - The route to use for fetching the product catalogue
     */
    async refreshRemoteCart(route: RouteLocationNormalized) {
      if (!remoteCart.value && auth.isTeacher) {
        await this.fetchRemoteCart(route);
      }
    },

    /**
     * Reset the cart. This will clear the cart and the trial.
     *
     * Note: It will not _delete_ the remote cart, use the `clear` method for that.
     */
    $reset() {
      remoteCart.value = undefined;
      trial.value = null;
      tempCart.value = [];
    },

    /**
     * Clear the cart. If the cart is remote, the cart will be cleared eagerly, before the API is called to clear the cart.
     *
     * @param route - The route to use for fetching the product catalogue
     */
    async clear(route: RouteLocationNormalized) {
      if (!auth.isTeacher) {
        return;
      }

      await this.fetchRemoteCart(route);

      trial.value = null;
      tempCart.value = [];

      if (remoteCart.value) {
        remoteCart.value = undefined;
        await cartApi.deleteCart();
        await this.fetchRemoteCart(route);
      }
    },

    /**
     * Add a temporary item to the cart. This is intended to be used when the user is logged out, and then moved to the remote cart as soon as the user logs in.
     *
     * @param variant - The variant to add
     * @param amount - The amount to add
     */
    addTempItem({ variant, amount }: NewCartItem) {
      if (import.meta.server) {
        return;
      }

      const values = new Map([[variant.isbn, amount]]);
      if (!variant.isTrial) {
        for (const temp of tempCart.value) {
          const tempVariant = productCatalogue.getVariant(temp.isbn);
          if (tempVariant) {
            const value = values.get(tempVariant.isbn) ?? 0;
            values.set(tempVariant.isbn, value + temp.amount);
          }
        }
      }

      tempCart.value = Array.from(
        values,
        ([isbn, amount]): TempCartItem => ({ isbn, amount }),
      );
    },

    /**
     * Hydrate the temporary items in the cart. This is intended to be used when the user logs in, to move the temporary items to the remote cart.
     *
     * @param route - The route to use for fetching the product catalogue
     */
    async hydrateTempItems(route: RouteLocationNormalized) {
      if (import.meta.server) {
        return;
      }

      await this.refreshRemoteCart(route);

      if (!remoteCart.value) {
        return;
      }

      for (const item of tempCart.value) {
        const remote = remoteCart.value.cartItems.find(
          (it) => it.productId === item.isbn,
        );

        const variant = productCatalogue.getVariant(item.isbn);

        if (variant) {
          if (remote && !variant.fixedAmount) {
            await updateAmount(remote, remote.amount + item.amount);
          } else if (!remote) {
            await this.add(
              {
                variant,
                amount: variant.fixedAmount ? auth.totStudents : item.amount,
              },
              {
                route,
                onSuccess() {},
              },
            );
          }
        } else {
          // TODO: Should we remove the item from the cart if the variant is not found?
        }
      }

      tempCart.value = [];
    },

    /**
     * Add an item to the cart. If the cart is remote, the item will be added eagerly, before the API is called to add the item. If the API call fails, the item will be removed from the cart.
     *
     * @param item - The item to add to the cart
     * @param route - The route to use for fetching the product catalogue
     * @param onSuccess - A callback to call if the item is successfully added to the cart
     * @param onTemp - A callback to call if the user is not authenticated
     */
    async add(
      { variant, amount }: NewCartItem,
      { route, onSuccess, onTemp }: AddOptions,
    ) {
      await productCatalogue.ensureProductCatalogue(route);

      if (variant.fixedAmount && amount !== auth.totStudents) {
        amount = auth.totStudents;
      }

      if (!auth.isAuthenticated) {
        this.addTempItem({ variant, amount });
        if (onTemp) {
          onTemp();
        } else {
          navigateToLogin(route);
        }
        return true;
      }

      if (!auth.isTeacher) {
        return;
      }

      if (variant.isTrial) {
        trial.value = variant.isbn;
        toast?.success("Prøvelisens lagt til i handlekurven");
        return;
      }

      trial.value = null;

      await this.refreshRemoteCart(route);

      if (!remoteCart.value) {
        return;
      }

      const existing = remoteCart.value.cartItems.find(
        (i) => i.productId === variant.isbn,
      );

      if (existing) {
        if (!variant.fixedAmount) {
          existing.amount += amount;

          try {
            await cartApi.updateCartItem(existing);
            toast?.success(
              "Antall i handlekurven oppdatert til " + existing.amount,
            );
            return true;
          } catch {
            existing.amount -= amount;
            toast?.error(
              "Kunne ikke legge til varen i handlekurven, ta kontakt med kundeservice om feilen vedvarer.",
            );
          }
        }

        toast?.info("Varen ligger allerede i handlekurven.");
        return false;
      }

      const relatedVariants = productCatalogue.getRelatedVariants(variant.isbn);

      if (relatedVariants) {
        if (
          remoteCart.value.cartItems.some(
            (it) => relatedVariants.parent?.isbn === it.productId,
          )
        ) {
          // Can't add a child variant if the parent is already in the cart
          toast?.info(
            "Du har allerede lagt til en variant av dette produktet i handlekurven.",
          );
          return false;
        }

        if (relatedVariants.children.length > 0) {
          const toBeRemoved = remoteCart.value.cartItems.filter((it) =>
            relatedVariants.children.some(
              (child) => child.isbn === it.productId,
            ),
          );

          toBeRemoved.forEach((it) => this.remove(it.uuid));
        }
      }

      const newItem: Omit<CartItemDto, "uuid"> = {
        productId: variant.isbn,
        amount,
        productName: variant.title,
        subscriptionPlan: variant.subscriptionsPlan?.identifier,
        subscriptionPeriod: variant.subscriptionsPlan?.period?.id,
      };

      try {
        const newCart = await cartApi.addItemToCart(newItem);

        if (newCart) {
          remoteCart.value = newCart;
        }

        if (onSuccess) {
          onSuccess();
        } else {
          toast?.success("Varen er lagt til i handlekurven");
        }
        return true;
      } catch {
        toast?.error(
          "Kunne ikke legge til varen i handlekurven, ta kontakt med kundeservice om feilen vedvarer.",
        );
        return false;
      }
    },

    /**
     * Remove an item from the cart. If the cart is remote, the item will be removed eagerly, before the API is called to remove the item. If the API call fails, the item will be added back to the cart.
     *
     * @param itemId - The uuid of the cart item to remove
     */
    async remove(itemId: string) {
      if (!auth.isTeacher) {
        return;
      }

      if (trial.value === itemId) {
        trial.value = null;
        return;
      }

      if (!remoteCart.value) {
        return;
      }

      const old = remoteCart.value.cartItems;
      const item = old.find((i) => i.uuid === itemId);

      if (!item) {
        return;
      }

      remoteCart.value.cartItems = old.filter((i) => i !== item);

      try {
        const newCart = await cartApi.deleteItemFromCart(itemId);
        if (newCart) {
          remoteCart.value = newCart;
        }
      } catch {
        remoteCart.value.cartItems = old;
        toast?.error(
          "Kunne ikke fjerne varen fra handlekurven, ta kontakt med kundeservice om feilen vedvarer.",
        );
      }
    },

    /**
     * Checkout the cart. If the user is not authenticated, the user will be redirected to the login page.
     *
     * Will also create a contact in Hubspot if the user is authenticated.
     *
     * @param route - The route to use for navigating
     */
    async checkout(route: RouteLocationNormalized) {
      if (!auth.isAuthenticated) {
        return navigateToLogin(route);
      }

      if (!auth.isTeacher) {
        return;
      }

      const organizationNumber = auth.selectedOrganization?.number;

      if (!organizationNumber) {
        toast?.error({
          message:
            "Vi fant ikke en organisasjon knyttet til din bruker. Vennligst ta kontakt med kundeservice.",
        });
        throw new Error("No organization number found");
      }

      try {
        await $fetch("/api/hubspot/contact", {
          method: "POST",
          headers: await auth.headers(),
        });
      } catch (e) {
        handleError(e, "Error creating contact in Hubspot", false);
      }

      if (trial.value) {
        const old = trial.value;
        try {
          await checkoutApi.initiateCheckoutSession("CRYSTALLIZE_SKOLE", {
            productId: trial.value,
            paymentSource: "TRIAL",
            organizationNumber,
          });

          trial.value = null;
        } catch {
          toast?.error(
            "Kunne ikke starte prøveperiode, ta kontakt med kundeservice om feilen vedvarer.",
          );
          trial.value = old;
        }
      } else if (!auth.hubspotData) {
        toast?.error({
          message:
            "Vi fant ikke en kunde knyttet til din organisasjon. Vennligst ta kontakt med kundeservice.",
          duration: 5000,
        });
        return false;
      } else if (!auth.hubspotData.referenceNumber) {
        toast?.error({
          message:
            "Vi mangler referansenummer for organisasjonen din. Dette må legges til på Min side for å få gjennomført kjøp.",
          persistent: true,
          buttons: [
            {
              text: "Gå til Min side",
              onClick: () => {
                navigateTo("/minside#referansenummer");
              },
            },
          ],
        });
        return false;
      } else {
        const old = remoteCart.value;
        try {
          await cartApi.startSubscriptions({
            customerType: "ORGANIZATION",
            customerOrganizationNumber: organizationNumber,
            buyerOrganizationNumber: organizationNumber,
            paymentSource: "INVOICE",
            customerNumber: `1-${auth.hubspotData.customerId}`,
            customerOriginId: auth.hubspotData.portalId,
            referenceNumber: auth.hubspotData.referenceNumber,
          });

          remoteCart.value = undefined;
        } catch {
          toast?.error(
            "Kunne ikke starte abonnement, ta kontakt med kundeservice om feilen vedvarer.",
          );

          remoteCart.value = old;
          return false;
        }

        try {
          await this.fetchRemoteCart(route);
          return true;
        } catch (e) {
          handleError(e, "Error fetching remote basket", false);
          return true;
        }
      }
    },

    updateAmount,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}

<script setup lang="ts">
import type { ArticleCard } from "~/types/articles";
import { ARTICLE_CARD_COLUMNS } from "~/types/enums";

const props = defineProps<{
  background?: string;
  title?: string;
  showAll?: boolean;
  noTopMargin?: boolean;
  range?: [number, number];
  articles?: ArticleCard[];
}>();

const store = useArticlesStore();

await useArticles();

const actualRange = computed<[number, number]>(() => {
  const max = Math.max(...ARTICLE_CARD_COLUMNS);
  if (!props.range) {
    return [0, max];
  }

  const [start, end] = props.range;

  if (end - start > max) {
    return [start, start + max];
  }

  return props.range;
});

const cards = computed(() => {
  const [start, end] = actualRange.value;
  return props.articles?.slice(start, end) ?? store.articleCards(start, end);
});

const data = reactive({
  textColorByClass: "#000000",
});

const sectionRef = ref<HTMLElement | undefined>();

onMounted(() => {
  if (sectionRef.value) {
    data.textColorByClass = getComputedStyle(sectionRef.value).color;
  }
});
</script>

<template>
  <section
    v-if="cards.length > 0"
    ref="sectionRef"
    :class="clsx('article-stripe', background)"
  >
    <h2
      v-if="title"
      :class="
        clsx('article-stripe__title', {
          'no-top-margin': noTopMargin,
          'article-stripe__title--centered': !showAll,
        })
      "
      v-text="title"
    />
    <div
      :class="
        cards.length < 3
          ? 'article-stripe__container article-stripe__container--left'
          : 'article-stripe__container'
      "
    >
      <CardsArticle
        v-for="article in cards"
        :key="article.id"
        :article="article"
        :width-factor="ARTICLE_CARD_COLUMNS.find((it) => it === cards.length)"
      />
    </div>
    <NuxtLink v-if="showAll" class="article-stripe__link" to="/artikler">
      <IconsArrowsRight :fill="data.textColorByClass" class="link__icon" />
      <span class="link__label" :style="`color: ${data.textColorByClass}`">
        Se alle artikler
      </span>
    </NuxtLink>
  </section>
</template>

<style scoped lang="scss">
.article-stripe {
  width: 100vw;
  margin: 0;
  padding: 2rem 0;

  &__title {
    margin: 1rem auto;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: pixelsToRem(20);
    letter-spacing: 0.1rem;
    width: min($max-width-article, 95%);

    @media screen and (min-width: $tablet) {
      margin: 2rem auto;
      width: min($max-width, 95%);
      text-align: left;
    }

    &.centered {
      text-align: center;
    }

    &.no-top-margin {
      margin-top: 0;
    }
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 2rem;
    align-items: stretch;
    max-width: $max-width;

    @media screen and (min-width: $tablet) {
      justify-content: space-between;
      gap: 0;
    }

    &--left {
      justify-content: center;
      gap: 2rem;

      @media screen and (min-width: $tablet) {
        justify-content: flex-start;
      }
    }
  }

  &__link {
    margin: 2rem auto;
    max-width: $max-width-article;
    display: flex;
    justify-content: center;
    gap: 1rem;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    color: $black;

    .link__icon,
    .link__label,
    .link__icon svg {
      transition: all 0.2s ease-in-out;
    }

    .link__label {
      font-size: 1rem;
      font-weight: 500;

      @media screen and (min-width: $extra-small) {
        font-size: 1.2rem;
      }
    }

    &:hover {
      color: $black;
      text-decoration: underline;

      .link__icon {
        transform: translateX(0.8rem);
      }

      svg path {
        fill: $black;
      }

      .link__label {
        transform: translateX(0.8rem);
      }
    }

    @media screen and (min-width: $tablet) {
      max-width: $max-width;
      justify-content: flex-start;
      margin: 3rem auto 4rem auto;
    }
  }
}
</style>

import {
  type ComponentFragment,
  type GetCatalogueQuery,
  type GetPageQuery,
  type ProductFragment,
  type GetPodcastCardsQuery,
} from "#gql/default";
import type { ImageSizeVariantsType } from "~/types/enums";
import type { MeteredPlan } from "~/types/product-catalogue";
import type { Level } from "~/utils/constants";

export type ItemRelation = NonNullable<
  UnionToIntersection<NonNullable<ComponentFragment["content"]>>["items"]
>[number];

export type EdgeNode = NonNullable<
  NonNullable<GetPodcastCardsQuery["catalogue"]>["subtree"]["edges"]
>[number]["node"];

export type Page =
  | NonNullable<NonNullable<GetCatalogueQuery["catalogue"]>["children"]>[number]
  | NonNullable<GetPageQuery["catalogue"]>;

export type ChildComponent = NonNullable<ItemRelation["components"]>[number];

type Component = ComponentFragment | ChildComponent;

type UnionToIntersection<U> = (U extends any ? (x: U) => void : never) extends (
  x: infer I,
) => void
  ? I
  : never;

type Content = UnionToIntersection<NonNullable<Component["content"]>>;

export function getSingleLine(component: Component): string {
  return resolveContentField(component, "text", "");
}

export function getEnumValues<T extends string>(
  component: Component,
  enumValues: ReadonlyArray<T>,
  optionalParts: string[] = [],
  separator = ",",
): T[] {
  const singleLine = getSingleLine(component);

  return singleLine
    .split(separator)
    .map((value) => {
      value = value.trim().toLowerCase();
      const parts = [...optionalParts];
      let match = enumValues.find(
        (enumValue) => enumValue.toLowerCase() === value,
      );

      while (!match && parts.length > 0) {
        const part = parts.pop();
        if (!part) {
          break;
        }

        match = enumValues.find(
          (enumValue) =>
            enumValue.toLowerCase().replace(part.toLowerCase(), "") === value,
        );
      }

      return match;
    })
    .filter(truthyAndDistinct);
}

export function getLevels(component: Component): Level[] {
  const selection = getSelections(component, "value");
  return selection
    .map((it) => LEVELS.find((l) => l === it))
    .filter(truthyAndDistinct);
}

export function getNumber(component: Component): number {
  return resolveContentField(component, "number", 0);
}

export function getBoolean(component: Component): boolean {
  return resolveContentField(component, "value", false);
}

export function getDatetime(component: Component): Date {
  const raw = resolveContentField(component, "datetime", new Date());

  return raw instanceof Date ? raw : new Date(raw);
}

export function getRepeatableChunks(component: Component) {
  return resolveContentField(component, "chunks", []);
}

export function getSingleChunks(component: Component) {
  const chunks = getRepeatableChunks(component)[0];
  if (Array.isArray(chunks)) {
    return chunks;
  } else if (chunks) {
    return [chunks];
  } else {
    return [];
  }
}

export type Chunk = ReturnType<typeof getSingleChunks>[number];

export function getItemRelations<C extends Component>(
  component: C,
): ItemRelation[] {
  return resolveContentField(component, "items", []);
}

export function getChunkData(chunks: Chunk[]) {
  const data: {
    tema?: string;
    items: ItemRelation[];
  } = {
    tema: "",
    items: [],
  };

  for (const chunk of chunks) {
    if (chunk.type === "itemRelations") {
      data.items = getItemRelations(chunk);
    } else if (chunk.type === "singleLine") {
      data.tema = getSingleLine(chunk);
    }
  }

  return data;
}

export function getSelections(
  component: Component,
  selector: "key" | "value" = "value",
) {
  const options = resolveContentField(component, "options", []);
  return options.map((option) => option[selector]);
}

export function getComponentChoice(component: Component) {
  return resolveContentField(component, "selectedComponent", {} as any);
}

export function getImages(component: Component) {
  return resolveContentField(component, "images", []);
}

export function getSingleImage(
  component: Component,
  width: ImageSizeVariantsType | "raw" = 500,
) {
  const image = getImages(component)[0];
  const variant =
    image &&
    getVariant({
      image,
      raw: width === "raw",
      preferredSize: width !== "raw" ? width : undefined,
    });

  return {
    img: variant?.url ?? image?.url,
    alt: image?.altText ?? "",
  };
}

type ImageFormat = "webp" | "avif" | "png" | "jpg" | "jpeg";

interface VariantOptions {
  image: ReturnType<typeof getImages>[number];
  preferredSize?: ImageSizeVariantsType;
  preferredFormats?: ImageFormat[];
  raw?: boolean;
}

export function getVariant({
  image,
  preferredSize = 500,
  preferredFormats = ["webp", "avif"],
  raw,
}: VariantOptions) {
  if (raw) {
    return undefined;
  }

  let variants = image?.variants?.filter(
    (variant) => variant.width === preferredSize,
  );

  if (!variants?.length) {
    // Can't find the preferred size, just pick the closest to what we want
    const closestWidth = image?.variants?.reduce<number>((prev, curr) => {
      if (
        prev === -1 ||
        Math.abs(curr.width - preferredSize) < Math.abs(prev - preferredSize)
      ) {
        return curr.width;
      }

      return prev;
    }, -1);

    variants = image?.variants?.filter(
      (variant) => variant.width === closestWidth,
    );
  }

  if (!variants?.length) {
    return undefined;
  }

  let variant;
  while (!variant) {
    const format = preferredFormats.shift();
    if (!format) {
      break;
    }

    variant = variants?.find((variant) => variant.url.endsWith(`.${format}`));
  }

  variant ??= variants?.[0];

  return variant;
}

export function getRichText(component: Component) {
  const plainText = resolveContentField(component, "plainText", []);
  if (plainText.every((it) => it.trim().length === 0)) {
    return "";
  }

  return resolveContentField(component, "html", []).join("");
}

export function getParagraphCollection(component: Component) {
  return resolveContentField(component, "paragraphs", [])
    .filter((it) => !!it.body)
    .flatMap((paragraph) => {
      const composed: string[] = [];

      if (paragraph.title?.text) {
        composed.push(`<h2>${paragraph.title.text}</h2>`);
      }

      if (paragraph.body?.html) {
        for (let i = 0; i < paragraph.body.html.length; i++) {
          const html = paragraph.body.html[i];
          const plainText = paragraph.body.plainText?.[i];
          const json = paragraph.body.json?.[i];
          if (html && plainText) {
            composed.push(html);
          } else {
            const lineBreaks =
              json?.children
                ?.filter((it: { type: string }) => it.type === "line-break")
                ?.map(() => "<br>") ?? [];

            composed.push(...lineBreaks);
          }
        }
      }

      if (paragraph.images) {
        composed.push(
          ...paragraph.images
            .map((image) => {
              let url = image.url;
              if (image.variants?.length) {
                const maxWidth = Math.max(
                  ...image.variants.map((v) => v.width),
                );

                const variant = getVariant({
                  image,
                  preferredSize: maxWidth as ImageSizeVariantsType,
                });

                if (variant) {
                  url = variant.url;
                }
              }

              if (!url) {
                return null;
              }

              const altText = image.altText ?? "";
              const caption = image.caption?.plainText?.[0];
              return `<figure><img src="${url}" alt="${altText}"/><figcaption>${caption}</figcaption></figure>`;
            })
            .filter(nonNullable),
        );
      }

      return composed;
    });
}

function resolveContentField<K extends keyof Content>(
  component: Component,
  fieldName: K,
): Content[K];
function resolveContentField<K extends keyof Content>(
  component: Component,
  fieldName: K,
  defaultValue: NonNullable<Content[K]>,
): NonNullable<Content[K]>;
function resolveContentField<K extends keyof Content>(
  component: Component,
  fieldName: K,
  defaultValue?: NonNullable<Content[K]>,
) {
  let value = defaultValue;

  const content = component?.content;
  if (content && fieldName in content) {
    const contentValue = content[fieldName as keyof typeof content];
    if (contentValue) {
      value = contentValue;
    }
  }

  return value;
}

type SubscriptionPlans = NonNullable<
  NonNullable<ProductFragment["variants"]>[number]["subscriptionPlans"]
>;

export function handleSubscriptionPlans(plans: SubscriptionPlans) {
  const plan = plans[0];
  if (!plan) {
    console.warn("No subscription plan found");
    return;
  } else if (plans.length > 1) {
    console.warn("Expected exactly one subscription plan", {
      count: plans.length,
    });
  }

  const meteredPlan: MeteredPlan = {
    identifier: plan.identifier,
    name: plan.name,
    meteredVariables: [],
  };

  const periods = plan.periods ?? [];
  const period = periods[0];
  if (!period) {
    console.warn("No subscription period found");
  } else if (periods.length > 1) {
    console.warn("Expected exactly one subscription period", {
      count: periods.length,
    });
  }

  if (period) {
    meteredPlan.period = {
      id: period.id,
      name: period.name,
      prices: {
        initial: getPrices(period.initial?.priceVariants ?? [], 0),
        recurring: getPrices(period.recurring?.priceVariants ?? [], 0),
      },
    };

    const tiers = period.recurring?.meteredVariables?.[0]?.tiers;
    if (tiers) {
      meteredPlan.meteredVariables = tiers.map((tier) => ({
        threshold: tier.threshold,
        price: tier.priceVariants?.[0]?.price ?? 0,
      }));
    }
  }

  return meteredPlan;
}

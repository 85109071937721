import type { GetCatalogueQuery } from "#gql";
import type { Packet, PacketVariant } from "~/types/universe";
import type { Level } from "~/utils/constants";
import type { RouteLocationNormalized } from "vue-router";
import type { SingleProduct } from "~/types/single-product";
import type { CartItemDto } from "~/types/core/ecommerce/cart";

export const useProductCatalogueStore = defineStore("productCatalogue", () => {
  const isFetching = ref(false);
  const products = ref<Array<Packet | SingleProduct>>([]);

  async function fetchProductCatalogue(route?: RouteLocationNormalized) {
    if (isFetching.value) {
      return;
    }

    isFetching.value = true;
    try {
      const options = (path: string) => ({
        path,
        language: "no-nb",
        version: useCrystallizeVersion(route),
      });

      const results = await Promise.all([
        // TODO: Remove this when everything has been moved to produktkatalog
        GqlGetCatalogue(options("/aunivers")),
        GqlGetCatalogue(options("/produktkatalog")),
      ]);

      products.value = results.flatMap((it) =>
        it.catalogue ? toProducts(it.catalogue) : [],
      );
    } catch (e) {
      handleError(e, "Failed to fetch product catalogue", false);
    } finally {
      isFetching.value = false;
    }
  }

  function getFromSlug(slug: string, level?: Level) {
    const matches = products.value.filter(bySlug(slug));

    return (
      (level && matches.find((packet) => packet.levels?.includes(level))) ||
      matches[0]
    );
  }

  return {
    products,
    fetchProductCatalogue,
    async ensureProductCatalogue(route?: RouteLocationNormalized) {
      if (!products.value.length) {
        await fetchProductCatalogue(route);
      }
    },

    getFromSlug,

    getVariants(slug: string, level?: Level) {
      return getFromSlug(slug, level)?.variants ?? [];
    },

    getVariant(isbnOrItem: string | CartItemDto) {
      const variants = products.value.map((it) => it.variants).flat();

      if (typeof isbnOrItem === "string") {
        return variants.find((it) => it.isbn === isbnOrItem);
      }

      let variant = variants.find((it) => it.isbn === isbnOrItem.productId);

      if (
        !variant &&
        isbnOrItem.subscriptionPlan &&
        isbnOrItem.subscriptionPeriod
      ) {
        variant = variants.find(
          (it) =>
            it.subscriptionsPlan?.identifier === isbnOrItem.subscriptionPlan &&
            it.subscriptionsPlan?.period?.id === isbnOrItem.subscriptionPeriod,
        );
      }

      if (!variant && isbnOrItem.productName) {
        variant = variants.find((it) => it.title === isbnOrItem.productName);
      }

      return variant;
    },

    getRelatedVariants(sku: string) {
      const variants = products.value
        .filter(isPacket)
        .flatMap((it) => it.variants);

      const self = variants.find((it) => it.sku === sku);

      if (!self) {
        return undefined;
      }

      let parent: PacketVariant | undefined;
      const children: PacketVariant[] = [];
      for (const variant of variants) {
        if (variant.relatedSubscription?.includes(self.sku)) {
          parent = variant;
        } else if (
          self.relatedSubscription?.includes(variant.sku) &&
          variant.included
        ) {
          children.push(variant);
        }
      }

      return { parent, children };
    },

    getCampaign(slug: string, level?: Level) {
      const matches = products.value.filter(isPacket).filter(bySlug(slug));

      const universe =
        (level && matches.find((packet) => packet.levels?.includes(level))) ||
        matches[0];

      return universe?.campaign;
    },

    getCards() {
      return products.value.map(toProductCard);
    },
  };
});

function toProducts(
  catalogue: GetCatalogueQuery["catalogue"],
): Array<Packet | SingleProduct> {
  const children = catalogue?.children;
  if (!children) {
    return [];
  }

  return children
    .map((it) => {
      if (it.shape.identifier === "aunivers-abonnement") {
        return toPacket(it);
      } else if (it.shape.identifier === "default-product") {
        return toProduct(it);
      } else {
        return null;
      }
    })
    .filter(nonNullable);
}

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useProductCatalogueStore, import.meta.hot),
  );
}

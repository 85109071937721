import { type ArticleType } from "@/types/articles";
import type { Level } from "~/utils/constants";

export const useArticlesStore = defineStore("articles", {
  state(): {
    crystallizeData: Array<ArticleType>;
    readCountId: string | undefined;
    readCounts: Record<string, number> | undefined;
  } {
    return {
      crystallizeData: [],
      readCountId: undefined,
      readCounts: undefined,
    };
  },

  getters: {
    findArticleFromSlug: (state) => {
      return (slug: string) => state.crystallizeData.find(bySlug(slug));
    },
    getRelatedArticles: (state) => {
      return (
        categories: Array<string> | undefined,
        size: number,
        currentArticle: ArticleType | undefined,
      ) => {
        if (!categories) {
          return [];
        }
        return (
          categories.includes("univers")
            ? state.crystallizeData.filter((article) =>
                article.title?.includes("univers"),
              )
            : state.crystallizeData
                .filter((article) =>
                  categories.some(
                    (category) =>
                      article.theme?.includes(category) ||
                      article.subject?.includes(category) ||
                      article.levels.includes(category as Level),
                  ),
                )
                .filter((article) => article.id !== currentArticle?.id)
        ) // this block should modify in case we go for a custom choice of articles in CMS and should remove ternary operator in favor of the second part
          .slice(0, size)
          .map(toArticleCard);
      };
    },
    articleCards: (state) => {
      return (startIndex = 0, limit = state.crystallizeData.length) =>
        state.crystallizeData
          .sort(byPublished())
          .map(toArticleCard)
          .slice(startIndex, limit);
    },
    getMostReadArticlesByLevel: (state) => {
      return (level: Level) =>
        state.crystallizeData
          .filter((article) => article.levels.includes(level))
          .sort(
            (a, b) =>
              (state.readCounts?.[b.id] || 0) - (state.readCounts?.[a.id] || 0),
          )
          .slice(0, 3);
    },
    getPodcastRelated: (state) => {
      return () =>
        state.crystallizeData.filter((article) => {
          return article.podcast !== "" || !!article.podcastEpisodes?.length;
        });
    },
  },

  actions: {
    async fetchArticles() {
      if (this.crystallizeData.length > 0) {
        return true;
      }

      try {
        const data = await GqlGetCatalogue({
          language: "no-nb",
          path: "/artikler",
          version: useCrystallizeVersion(),
        });

        this.crystallizeData =
          data?.catalogue?.children?.map(toArticle).sort(byPublished()) || [];

        return true;
      } catch (error) {
        handleError(error, "Error fetching articles");
      }
    },
    async ensureArticles() {
      if (this.crystallizeData.length === 0) {
        try {
          await this.fetchArticles();
        } catch (e) {
          console.error(e);
        }
      }
    },
    async fetchReadCounts() {
      const data = await GqlGetArticleCounts({
        language: "no-nb",
      });

      this.readCountId = data.catalogue?.id;
      this.readCounts = toReadCounts(data);
      return this.readCounts;
    },
    async ensureReadCounts() {
      if (!this.readCounts) {
        try {
          await this.fetchReadCounts();
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
});

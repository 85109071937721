<script setup lang="ts">
const settings = useSettingsStore();
</script>

<template>
  <ul class="social">
    <li v-if="settings.some?.facebook">
      <a
        :href="settings.some.facebook"
        aria-label="Link til Facebook side"
        target="_blank"
      >
        <IconsSocialFacebook aria-hidden="true" />
      </a>
    </li>
    <li v-if="settings.some?.instagram">
      <a
        :href="settings.some.instagram"
        aria-label="Link til Instagram side"
        target="_blank"
      >
        <IconsSocialInstagram aria-hidden="true" />
      </a>
    </li>
    <li v-if="settings.some?.linkedin">
      <a
        :href="settings.some.linkedin"
        aria-label="Link til Linkedin side"
        target="_blank"
      >
        <IconsSocialLinkedin aria-hidden="true" />
      </a>
    </li>
    <li v-if="settings.some?.twitter">
      <a
        :href="settings.some.twitter"
        aria-label="Link til Twitter side"
        target="_blank"
      >
        <IconsSocialTwitter aria-hidden="true" />
      </a>
    </li>
    <li v-if="settings.some?.tiktok">
      <a
        :href="settings.some.tiktok"
        aria-label="Link til Tiktok side"
        target="_blank"
      >
        <IconsSocialTikTok aria-hidden="true" />
      </a>
    </li>
    <li v-if="settings.some?.youtube">
      <a
        :href="settings.some.youtube"
        aria-label="Link til Youtube channel side"
        target="_blank"
      >
        <IconsSocialYouTube aria-hidden="true" />
      </a>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.social {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;

  li {
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
